import { httpApi } from '@app/api/http.api';
// import './mocks/auth.api.mock';
// import { UserModel } from '@app/domain/UserModel';
import { ApiUrls } from './ApiUrls';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  password: string;
  email: string;
  token: string | null;
}
export interface ChangePasswordData {
  oldPassword: string;
  password: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

// export interface LoginResponse {
//   token: string;
//   user: UserModel;
// }

export const login = (loginPayload: LoginRequest): Promise<any> =>
  httpApi.post(ApiUrls.login, { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>(ApiUrls.register, { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>(ApiUrls.requestResetPassword, { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>(ApiUrls.resetPassword, { ...newPasswordData }).then(({ data }) => data);

export const changePassword = (data: ChangePasswordData): Promise<undefined> =>
  httpApi.put<undefined>(ApiUrls.changePassword, data).then(({ data }) => data);
