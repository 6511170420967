import React from 'react';
import {
  CompassOutlined,
  DashboardOutlined,
  FormOutlined,
  HomeOutlined,
  LayoutOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
  BlockOutlined,
  ProjectOutlined,
  PrinterOutlined,
  ReconciliationOutlined,
  TeamOutlined,
  ShareAltOutlined,
  PartitionOutlined,
  RiseOutlined,
  FallOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { ReactComponent as NftIcon } from '@app/assets/icons/nft-icon.svg';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Liste des memo',
    key: 'memo-list',
    // TODO use path variable
    url: '/memo/list',
    icon: <RiseOutlined />,
  },
  {
    title: 'Liste des memo valider',
    key: 'memo-old',
    url: '/memo/old',
    icon: <FallOutlined />,
  },
  {
    title: 'Memo',
    key: 'memo-index',
    // TODO use path variable
    url: '/memo',
    icon: <PrinterOutlined />,
  },
  {
    title: 'Division',
    key: 'memo-division',
    // TODO use path variable
    url: '/memo/divisions',
    icon: <TableOutlined />,
  },
  {
    title: 'Chronologie',
    key: 'memo-timeline',
    // TODO use path variable
    url: '/memo/timeline',
    icon: <ShareAltOutlined />,
  },
  {
    title: 'Etapes',
    key: 'memo-step',
    // TODO use path variable
    url: '/memo/step',
    icon: <PartitionOutlined />,
  },
  {
    title: 'Templates',
    key: 'memo-templates',
    // TODO use path variable
    url: '/memo/templates',
    icon: <ReconciliationOutlined />,
  },
  {
    title: 'Role',
    key: 'memo-role',
    // TODO use path variable
    url: '/memo/roles',
    icon: <TeamOutlined />,
  },
  {
    title: 'Users',
    key: 'memo-ussers',
    // TODO use path variable
    url: '/memo/users',
    icon: <UserAddOutlined />,
  },
];
export const sidebarNavigationUser: SidebarNavigationItem[] = [
  {
    title: 'Liste des memo',
    key: 'memo-list',
    // TODO use path variable
    url: '/memo/list',
    icon: <RiseOutlined />,
  },
  {
    title: 'Liste des memo valider',
    key: 'memo-old',
    url: '/memo/old',
    icon: <FallOutlined />,
  },
];

export const sidebarNavigationMemo: SidebarNavigationItem[] = [
  {
    title: 'Liste des memo',
    key: 'memo-list',
    // TODO use path variable
    url: '/memo/list',
    icon: <RiseOutlined />,
  },
  {
    title: 'Liste des memo valider',
    key: 'memo-old',
    url: '/memo/old',
    icon: <FallOutlined />,
  },
  {
    title: 'Memo',
    key: 'memo-index',
    // TODO use path variable
    url: '/memo',
    icon: <PrinterOutlined />,
  },
  {
    title: 'Division',
    key: 'memo-division',
    // TODO use path variable
    url: '/memo/divisions',
    icon: <TableOutlined />,
  },
  {
    title: 'Chronologie',
    key: 'memo-timeline',
    // TODO use path variable
    url: '/memo/timeline',
    icon: <ShareAltOutlined />,
  },
  {
    title: 'Etapes',
    key: 'memo-step',
    // TODO use path variable
    url: '/memo/step',
    icon: <PartitionOutlined />,
  },
  {
    title: 'Templates',
    key: 'memo-templates',
    // TODO use path variable
    url: '/memo/templates',
    icon: <ReconciliationOutlined />,
  },
];
