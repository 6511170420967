import { readToken } from '@app/services/localStorage.service';
import { RootState, store } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const stepApi = createApi({
  reducerPath: 'step-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Step'],
  endpoints: (builder) => ({
    getSteps: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: 'step/',
        params: { page, limit },
      }),
      providesTags: ['Step'],
    }),

    getStepById: builder.query({
      query: (id) => `step/${id}`,
      providesTags: ['Step'],
    }),

    createStep: builder.mutation({
      query: (newStep) => ({
        url: 'step',
        method: 'POST',
        body: newStep,
      }),
      invalidatesTags: ['Step'],
    }),

    updateStep: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `step/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Step'],
    }),

    deleteStep: builder.mutation({
      query: (id) => ({
        url: `step/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Step'],
    }),
  }),
});

export const {
  useGetStepsQuery,
  useGetStepByIdQuery,
  useCreateStepMutation,
  useUpdateStepMutation,
  useDeleteStepMutation,
} = stepApi;
