import { httpApi } from '@app/api/http.api';
import { ApiUrls } from './ApiUrls';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { readToken } from '@app/services/localStorage.service';
import { RootState } from '@app/store/store';
export interface DivisionRequest {
  name: string;
  slug: string;
}

// export const createDivision = (divisionPayload: DivisionRequest): Promise<any> =>
//   httpApi.post(ApiUrls.divsion, { ...divisionPayload }).then(({ data }) => data);

// export const deleteDivision = (id: string): Promise<any> =>
//   httpApi.delete(`${ApiUrls.divsion}/${id}`).then(({ data }) => data);

// export const updateDivision = (divisionPayload: any): Promise<any> =>
//   httpApi
//     .patch(`${ApiUrls.divsion}/${divisionPayload.id}`, { name: divisionPayload.name, slug: divisionPayload.slug })
//     .then(({ data }) => data);

export const apiDivision = createApi({
  reducerPath: 'division',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Division'],
  endpoints: (builder) => ({
    getDivisions: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: ApiUrls.divsion,
        params: { page, limit },
      }),
      providesTags: ['Division'],
    }),
    createDivision: builder.mutation({
      query: (newDivision) => ({
        url: 'division',
        method: 'POST',
        body: newDivision,
      }),
      invalidatesTags: ['Division'],
    }),

    updateDivision: builder.mutation({
      query: ({ id, form }) => ({
        url: `division/${id}`,
        method: 'PATCH',
        body: form,
      }),
      invalidatesTags: ['Division'],
    }),

    deleteDivision: builder.mutation({
      query: (id) => ({
        url: `division/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Division'],
    }),
  }),
});

export const { useGetDivisionsQuery, useCreateDivisionMutation, useUpdateDivisionMutation, useDeleteDivisionMutation } =
  apiDivision;
