import { readToken } from '@app/services/localStorage.service';
import { RootState, store } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const permissionApi = createApi({
  reducerPath: 'permission-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Permission'],
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: 'permission',
        params: { page, limit },
      }),
      providesTags: ['Permission'],
    }),

    getPermissionById: builder.query({
      query: (id) => `permission/${id}`,
      providesTags: ['Permission'],
    }),

    createPermission: builder.mutation({
      query: (newPermission) => ({
        url: 'permission',
        method: 'POST',
        body: newPermission,
      }),
      invalidatesTags: ['Permission'],
    }),

    updatePermission: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `permission/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Permission'],
    }),

    deletePermission: builder.mutation({
      query: (id) => ({
        url: `permission/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Permission'],
    }),
  }),
});

export const {
  useGetPermissionsQuery,
  useGetPermissionByIdQuery,
  useCreatePermissionMutation,
  useUpdatePermissionMutation,
  useDeletePermissionMutation,
} = permissionApi;
