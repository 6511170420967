import { configureStore } from '@reduxjs/toolkit';
import { errorLoggingMiddleware } from '@app/store/middlewares/errorLogging.middleware';
import rootReducer from '@app/store/slices';
import { apiDivision } from '@app/api/division.api';
import { userApi } from '@app/api/user.api';
import { memoTemplateApi } from '@app/api/memo-template-api';
import { stepApi } from '@app/api/step.api';
import { timeLineApi } from '@app/api/timeline.api';
import { memoApi } from '@app/api/memo.api';
import { roleApi } from '@app/api/role.api';
import { routeApi } from '@app/api/routes.api';
import { permissionApi } from '@app/api/permission.api';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiDivision.middleware,
      userApi.middleware,
      memoTemplateApi.middleware,
      stepApi.middleware,
      timeLineApi.middleware,
      memoApi.middleware,
      roleApi.middleware,
      routeApi.middleware,
      permissionApi.middleware,
      errorLoggingMiddleware,
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
