import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { notificationController } from '@app/controllers/notificationController';
import { store } from '../store';
import { doLogin, doLogout } from '../slices/authSlice';
import { apiDivision } from '@app/api/division.api';
import { memoTemplateApi } from '@app/api/memo-template-api';
import { userApi } from '@app/api/user.api';

/**
 * Log a warning and show a toast!
 */
export const errorLoggingMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const message = action.payload?.data?.message;

    if (action.payload.status === 401) {
      store.dispatch(doLogout());
      store.dispatch(apiDivision.util.invalidateTags(['Division']));
      store.dispatch(memoTemplateApi.util.invalidateTags(['MemoTemplate']));
      store.dispatch(userApi.util.invalidateTags(['User']));
      return;
    }
    if (Array.isArray(message)) {
      message.map((error: string) => {
        notificationController.error({ message: error });
      });
    } else {
      notificationController.error({ message: message });
    }
  }

  return next(action);
};
