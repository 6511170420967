import { readToken } from '@app/services/localStorage.service';
import { RootState, store } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Définit l'API slice
export const timeLineApi = createApi({
  reducerPath: 'timeLine-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['TimeLine'],
  endpoints: (builder) => ({
    getTimeLines: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: 'timeline/',
        params: { page, limit },
      }),
      providesTags: ['TimeLine'],
    }),

    getTimeLineById: builder.query({
      query: (id) => `timeline/${id}`,
      providesTags: ['TimeLine'],
    }),

    createTimeLine: builder.mutation({
      query: (newTimeLine) => ({
        url: 'timeline',
        method: 'POST',
        body: newTimeLine,
      }),
      invalidatesTags: ['TimeLine'],
    }),

    updateTimeLine: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `timeline/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['TimeLine'],
    }),

    deleteTimeLine: builder.mutation({
      query: (id) => ({
        url: `timeline/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TimeLine'],
    }),
  }),
});

export const {
  useGetTimeLinesQuery,
  useGetTimeLineByIdQuery,
  useCreateTimeLineMutation,
  useUpdateTimeLineMutation,
  useDeleteTimeLineMutation,
} = timeLineApi;
