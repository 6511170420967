import { readToken } from '@app/services/localStorage.service';
import { RootState, store } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Définit l'API slice
export const memoApi = createApi({
  reducerPath: 'memo-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Memo'],
  endpoints: (builder) => ({
    getMemos: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: 'memo/',
        params: { page, limit },
      }),
      providesTags: ['Memo'],
    }),

    getMemoById: builder.query({
      query: (id) => `memo/${id}`,
      providesTags: ['Memo'],
    }),
    getMemoBySlug: builder.query({
      query: ({ page = 1, limit = 10, slug }) => ({
        url: `memo/${slug}/slug/`,
        params: { page, limit },
      }),
      providesTags: ['Memo'],
    }),

    createMemo: builder.mutation({
      query: (newMemo) => ({
        url: 'memo',
        method: 'POST',
        body: newMemo,
      }),
      invalidatesTags: ['Memo'],
    }),

    nextRoute: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `memo/${id}/next`,
        method: 'POST',
        body: rest,
      }),
      invalidatesTags: ['Memo'],
    }),

    updateMemo: builder.mutation({
      query: ({ id, form }) => ({
        url: `memo/${id}`,
        method: 'PATCH',
        body: form,
      }),
      invalidatesTags: ['Memo'],
    }),

    deleteMemo: builder.mutation({
      query: (id) => ({
        url: `memo/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Memo'],
    }),
    deleteMemoMedia: builder.mutation({
      query: ({ id, mediaId }) => ({
        url: `memo/${id}/media/${mediaId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Memo'],
    }),
  }),
});

export const {
  useGetMemosQuery,
  useGetMemoByIdQuery,
  useGetMemoBySlugQuery,
  useCreateMemoMutation,
  useUpdateMemoMutation,
  useNextRouteMutation,
  useDeleteMemoMutation,
  useDeleteMemoMediaMutation,
} = memoApi;
