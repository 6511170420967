import { readToken } from '@app/services/localStorage.service';
import { RootState } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Définit l'API slice
export const memoTemplateApi = createApi({
  reducerPath: 'memoTemplate-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['MemoTemplate'],
  endpoints: (builder) => ({
    getMemoTemplates: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: 'memo-template/',
        params: { page, limit },
      }),
      providesTags: ['MemoTemplate'],
    }),

    getMemoTemplateById: builder.query({
      query: (id) => `memo-template/${id}`,
      providesTags: ['MemoTemplate'],
    }),

    createMemoTemplate: builder.mutation({
      query: (newMemoTemplate) => ({
        url: 'memo-template',
        method: 'POST',
        body: newMemoTemplate,
      }),
      invalidatesTags: ['MemoTemplate'],
    }),

    updateMemoTemplate: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `memo-template/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['MemoTemplate'],
    }),

    deleteMemoTemplate: builder.mutation({
      query: (id) => ({
        url: `memo-template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MemoTemplate'],
    }),
  }),
});

export const {
  useGetMemoTemplatesQuery,
  useGetMemoTemplateByIdQuery,
  useCreateMemoTemplateMutation,
  useUpdateMemoTemplateMutation,
  useDeleteMemoTemplateMutation,
} = memoTemplateApi;
