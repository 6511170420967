import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import RequireAdmin from './RequireAdmin';
import RequireMemoOrAdmin from './RequireMemoOrAdmin';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { is } from '@app/utils/utils';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));

const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const Logout = React.lazy(() => import('./Logout'));

const MemoDivisionPage = React.lazy(() => import('@app/pages/divisionPage/DivisionPage'));
const MemoTemplatePage = React.lazy(() => import('@app/pages/memoTemplatesPage/MemoTemplatesPage'));
const UsersPage = React.lazy(() => import('@app/pages/usersPages/UsersPages'));
const CrMemoPage = React.lazy(() => import('@app/pages/memoPage/CrMemoPage'));
const CurrentMemoListPage = React.lazy(() => import('@app/pages/memoPage/CurrentMemoListPage'));
const OldMemoLiatPage = React.lazy(() => import('@app/pages/memoPage/OldMemoListPage'));
const TimeLinePage = React.lazy(() => import('@app/pages/timeLinePages/TimeLinePage'));
const RolePage = React.lazy(() => import('@app/pages/Roles/RolePage'));
const StepPage = React.lazy(() => import('@app/pages/StepPage/StepPage'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
//Memo

const Division = withLoading(MemoDivisionPage);
const Role = withLoading(RolePage);
const Template = withLoading(MemoTemplatePage);
const MemoUsers = withLoading(UsersPage);
const TimeLine = withLoading(TimeLinePage);
const Step = withLoading(StepPage);
const CrMemo = withLoading(CrMemoPage);
const CurrentMemo = withLoading(CurrentMemoListPage);
const OldMemo = withLoading(OldMemoLiatPage);

export const AppRouter: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          {user && <Route index element={<Navigate to={is(user, 'user') ? 'memo/list' : 'memo'} replace />} />}
          <Route path="memo">
            <Route
              index
              element={
                <RequireMemoOrAdmin>
                  <CrMemo />
                </RequireMemoOrAdmin>
              }
            />
            <Route
              path="divisions"
              element={
                <RequireMemoOrAdmin>
                  <Division />
                </RequireMemoOrAdmin>
              }
            />
            <Route
              path="roles"
              element={
                <RequireAdmin>
                  <Role />
                </RequireAdmin>
              }
            />
            <Route
              path="templates"
              element={
                <RequireMemoOrAdmin>
                  <Template />
                </RequireMemoOrAdmin>
              }
            />
            <Route
              path="users"
              element={
                <RequireAdmin>
                  <MemoUsers />
                </RequireAdmin>
              }
            />
            <Route
              path="timeline"
              element={
                <RequireMemoOrAdmin>
                  <TimeLine />
                </RequireMemoOrAdmin>
              }
            />
            <Route
              path="step"
              element={
                <RequireMemoOrAdmin>
                  <Step />
                </RequireMemoOrAdmin>
              }
            />
            <Route path="list" element={<CurrentMemo />} />
            <Route path="old" element={<OldMemo />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="reset-password/*" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};
