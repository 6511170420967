import { readToken } from '@app/services/localStorage.service';
import { RootState, store } from '@app/store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const routeApi = createApi({
  reducerPath: 'route-api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Route'],
  endpoints: (builder) => ({
    getRoutes: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: 'route/',
        params: { page, limit },
      }),
      providesTags: ['Route'],
    }),

    getRouteByUserId: builder.query({
      query: ({ page = 1, limit = 10 }) => ({
        url: `route/old/validation/`,
        params: { page, limit },
      }),
      providesTags: ['Route'],
    }),

    createRoute: builder.mutation({
      query: (newRoute) => ({
        url: 'route',
        method: 'POST',
        body: newRoute,
      }),
      invalidatesTags: ['Route'],
    }),

    updateRoute: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `route/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Route'],
    }),

    deleteRoute: builder.mutation({
      query: (id) => ({
        url: `route/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Route'],
    }),
  }),
});

export const {
  useGetRoutesQuery,
  useGetRouteByUserIdQuery,
  useCreateRouteMutation,
  useUpdateRouteMutation,
  useDeleteRouteMutation,
} = routeApi;
