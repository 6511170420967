import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import { apiDivision } from '@app/api/division.api';
import { userApi } from '@app/api/user.api';
import { memoTemplateApi } from '@app/api/memo-template-api';
import { timeLineApi } from '@app/api/timeline.api';
import { stepApi } from '@app/api/step.api';
import { memoApi } from '@app/api/memo.api';
import { roleApi } from '@app/api/role.api';
import { routeApi } from '@app/api/routes.api';
import { permissionApi } from '@app/api/permission.api';

export default {
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  [apiDivision.reducerPath]: apiDivision.reducer,
  [memoTemplateApi.reducerPath]: memoTemplateApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [timeLineApi.reducerPath]: timeLineApi.reducer,
  [stepApi.reducerPath]: stepApi.reducer,
  [memoApi.reducerPath]: memoApi.reducer,
  [roleApi.reducerPath]: roleApi.reducer,
  [routeApi.reducerPath]: routeApi.reducer,
  [permissionApi.reducerPath]: permissionApi.reducer,
};
