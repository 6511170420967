import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { is } from '@app/utils/utils';

const RequireAdmin: React.FC<WithChildrenProps> = ({ children }) => {
  const user = useAppSelector((state) => state.user.user);
  return is(user, 'admin') ? <>{children}</> : <Navigate to="/404" replace />;
};

export default RequireAdmin;
